import React from 'react';
import appLogo from "../imgs/logo.webp";
import appStoreLogo from "../imgs/appstore-logo.webp"

const MainPage = () => {
    return (
        <div className="main-container">
            <div className="content-div">
                <img className="app-logo" src={appLogo} style={{width: "10vw"}} alt="App Logo"/>
                <div className="title-text title-header">
                    Temperie
                </div>
                <div className="title-text">
                    Top Watch Faces
                </div>
                <div className="description-div">
                    Effortless Personalization at Your Fingertips: <br /> Tailor Your Apple Watch Experience.
                </div>
                <a href="https://apps.apple.com/il/app/top-watch-faces-by-temperie/id6499347539">
                    <img className="appstore-logo" style={{width: "15vw"}} src={appStoreLogo} alt="Appstore Logo" />
                </a>
                <div className="second-description-div">
                    One-Tap Wonders: Effortless Apple <br />Watch Customization
                </div>
                <div className="links-div">
                    <a target="_blank" href="/terms">Terms of Use</a>
                    <a target="_blank" href="/privacy">Privacy Policy</a>
                    <a href="mailto:contact@temperie.co">Contact Us</a>
                </div>
                <div className="footer">
                    <span>Top Watch Faces by TEMPERIE CREATIVE LTD</span>
                    <span>Email: contact@temperie.co</span>
                    <span>All Rights Reserved 2025</span>
                </div>
            </div>
        </div>
    );
};

export default MainPage;